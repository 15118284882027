.box {
    background-color: rgb(255, 255, 255);
    width: 500px;
    height: 700px;
    border: 3px solid #BFA288;
    padding: 50px;
    margin: 20px;
}

@media screen and (max-width: 580px) {
    .box {
        background-color: rgb(255, 255, 255);
        width: auto;
        height: 800px;
        border: 3px solid #BFA288;
        padding: 50px;
        margin: 20px;
    }
  }